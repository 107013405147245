import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PHP_API_SERVER } from './constants';

@Injectable({
  providedIn: 'root'
})
export class CommercialService {

  constructor(private httpClient:HttpClient) { }

  connectuser(user:string,mdp:string){
    return this.httpClient.get<any>(`${PHP_API_SERVER}/api/Commercial/connexion.php/?user=${user}&mdp=${mdp}`);
  }
  Dashboard1(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/dashboard1.php/?codecommercialP=${codecom}`);
  }
  MesClients(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/mesclients.php/?codecommercialP=${codecom}`);
  }
  MesCommandes(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/mescommandes.php/?codecommercialP=${codecom}`);
  }
  DetailsCde(numcde:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/detailscde.php/?numcde=${numcde}`);
  }
  CommandesClient(codecli:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/commandesclient.php/?codecli=${codecli}`);
  }
  ListeClients(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/listeclients.php/?codecommercialP=${codecom}`);
  }
  Modesliv(): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/modesliv.php`);
  }
  Modespaiement(): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/modespaiement.php`);
  }
  Finaliser(commande: any,codecom:string): Observable<any>{
    commande.codecommercial=codecom;
    return this.httpClient.post<any>(`${PHP_API_SERVER}/api/Commercial/finalisercommande.php`, commande);
  }
  Sauvegarderfiltre(historique:any): Observable<any>{
    return this.httpClient.post<any>(`${PHP_API_SERVER}/api/Commercial/sauvegarde.php`, historique);
  }
  Stats(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/stats.php/?codecommercialP=${codecom}`);
  }
  MesDernieresRecherches(codecom:string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${PHP_API_SERVER}/api/Commercial/dernieresrecherches.php/?codecommercialP=${codecom}`);
  }
}
