import { Component,OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { CommercialService } from './commercial.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  login:boolean=false;
  mdptype:string="password";
  nomcommercial:string="";

  public appPages = [
    { title: 'Acceuil', url: '/acceuil', icon: 'home' },
    { title: 'Familles', url: '/filtre/familles', icon: 'albums' },
  ];
  ngOnInit(): void {
    if(localStorage.getItem("login")=="true"){
      this.login=true;
      this.nomcommercial=localStorage.getItem("nom");
    }
  }

  constructor(private srv:CommercialService,private toastCtrl:ToastController) {}
  deconnexion(){
    localStorage.setItem("login","");
    this.login=false;
  }
  showpassword(){
    if(this.mdptype=="password")
    this.mdptype="text";
    else
    this.mdptype="password";
  }
  formulaire(data:any){
    this.srv.connectuser(data['identifiant'],data['mdp']).subscribe(
      async (resp:any) => { 
      if (resp.codecom=='null'){
        const toast = await this.toastCtrl.create({  
          message: 'Informations erronées !!',   
          duration: 2000  
        });  
        toast.present();
      } 
      else{ 
        this.login=true;
        localStorage.setItem("login","true");
        localStorage.setItem("code",resp.codecom);
        localStorage.setItem("nom",resp.nomcommercial);
        console.log(resp);
        this.nomcommercial=resp.nomcommercial;
      }
    },async (err) => {
      console.log(err);
      const toast = await this.toastCtrl.create({  
        message: 'Erreur , veuillez réssayer plus tard !',   
        duration: 2000  
      });
      toast.present(); 
    });
}}
